import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
// import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
// import useQuery from "./useQuery";
import { login } from "service/authService";
import { CLIENT } from "constants/roles.constant";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const query = useQuery();

  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );

  const signIn = async (data) => {
    try {
      const resp = await login(data);
      if (resp?.success) {
        const token = resp?.jwt;
        dispatch(
          setUser({
            id: resp?.user?._id ? resp?.user?._id : "null",
            username: resp?.user?.username ? resp?.user?.username : "user",
            email: resp?.user?.email.toLowerCase().trim()
              ? resp?.user?.email.toLowerCase().trim()
              : "",
            phoneNumber: resp?.user?.phoneNumber ? resp?.user?.phoneNumber : "",
            birthDate: resp?.user?.birthDate ? resp?.user?.birthDate : "",
            profilePhoto: resp?.user?.profilePhoto
              ? resp?.user?.profilePhoto
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
            authority: resp?.user?.user_role?.role
              ? [resp?.user?.user_role?.role.trim()]
              : ["employee"]
          })
        );
        if (resp?.canVerify === false) {
          dispatch(onSignInSuccess(token));
          // const redirectUrl = query.get(REDIRECT_URL_KEY);
          navigate(
            resp?.user?.user_role?.role.trim() === CLIENT
              ? appConfig.authenticatedClientEntryPath
              : appConfig.authenticatedEntryPath
          );
        } else {
          navigate(appConfig.verifyPath, {
            state: { email: resp?.user?.email }
          });
        }

        // dispatch(onSignInSuccess(token));
        // const redirectUrl = query.get(REDIRECT_URL_KEY);
        // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: ""
        };
      } else {
        return resp;
      }
    } catch (err) {
      console.log("signIn error : ", err);
      return {
        status: "failed",
        message: err?.response?.data?.error?.message || err.toString()
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn && expired > new Date().getTime(),
    signIn,
    signOut
  };
}

export default useAuth;
